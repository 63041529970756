<template>
  <div class="min-h-contain">
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">{{ $route.meta.label }}</h1>
        <Button :buttonText="modeForm === 'Edit' ? 'Update' : 'Submit'" @action="onBack" v-if="roleSelected.length == 14" />
        <Button :buttonText="modeForm === 'Edit' ? 'Update' : 'Submit'" :disabled="isSubmitDisabled && !isEditMode" @action="submitData" v-else />
      </div>
    </div>
    <div class="shadow-small rounded-lg bg-white mb-6 p-6">
      <div class="mb-6 w-1/2" v-if="this.modeForm == 'Tambah'">
        <div class="relative">
          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Select Customer <span class="text-red">*</span></label>
          <div class="relative">
            <TextField borderEnabled withIcon v-model="customerKeyword" @input="searchCustomer()" placeholder="Search Customer..." />
            <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
              <Search />
            </div>
          </div>
          <div class="absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-black bg-opacity-80 backdrop-filter backdrop-blur-sm z-10" v-if="isSearching">
            <template v-if="customerList.length > 0 && !isFetching">
              <div
                @click="selectCustomer(customer)"
                class="py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0"
                v-for="customer in customerList"
                :key="customer.id"
              >
                {{ customer.name }}
              </div>
            </template>
            <template v-if="customerList.length === 0 && !isFetching">
              <p class="py-1 px-3 text-white font-normal text-center">Customer not found.</p>
            </template>
          </div>
        </div>
      </div>
      <div class="mb-6 w-1/2" v-else>
        <TextField type="text" label="Customer" :borderEnabled="true" v-model="options.customerName" disabled />
      </div>
      <div class="mb-6 w-1/2" v-if="this.modeForm == 'Edit'">
        <TextField type="text" label="Email" :borderEnabled="true" placeholder="Enter email" v-model="options.email" disabled />
      </div>
      <div class="mb-6 w-1/2" v-else>
        <TextField type="text" label="Email" :borderEnabled="true" isLabelRequire placeholder="Enter email" v-model="options.email" :error="errors['email']" />
      </div>
      <div class="w-2/4 rounded-lg border-2 p-5">
        <div class="flex mb-6" v-for="(data, idx) in roleSelected" :key="idx">
          <div class="w-full">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Role {{ idx + 1 }}</label>
            <Dropdown placeholder="Select Role" :options="roleOptions" :default="roleSelected[idx].roleName" :disabled="isDisabled" v-if="data.customerUserRoleId != null" />
            <Dropdown placeholder="Select Role" :options="roleOptions" v-model="options.role" :default="roleSelected[idx].roleName" :error="errors['role']" v-else />
          </div>
          <div class="w-12 mx-6 flex justify-center items-end pb-2">
            <Button
              type="secondary"
              size="icon"
              :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
              :tooltip="{ show: true, position: '140%', text: 'Delete' }"
              @action="toggleConfirm(idx, data)"
            />
          </div>
        </div>
        <Button buttonText="Add Role" type="tertiary" @action="addRole" v-if="initialRoleCount >= roleSelected.length && roleSelected.length != 14" />
      </div>
    </div>
    <Modal :modalVisible="visibleConfirmation" @close="closeConfirmModal" width="640px" id="confirm">
      <template slot="modal-content">
        <div class="py-5">
          <div class="font-bold text-2xl mb-5">Are you sure to remove this role?</div>
          <!-- <div class="py-5 text-sm">This account will no longer have access to several features in this environment.</div> -->
          <div class="flex justify-center items-center gap-5">
            <Button buttonText="Remove" type="secondary" size="long" @action="deleteUserRole(deletedIndex, deletedRole?.customerUserRoleId)" />
            <Button buttonText="Cancel" type="primary" size="long" @action="closeConfirmModal()" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showVueToast, isNotEmptyErrorForm } from '@/utils'
const CONFIG = {
  email: 'Email',
  role: 'Role'
}
export default {
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Alert: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Alert')
  },
  name: 'RolesForm',
  data() {
    return {
      errors: [],
      isDisabled: true,
      isClicked: true,
      stillLoading: false,
      isUpdateButtonDisabled: false,
      idForm: 0,
      modeForm: null,
      options: {
        name: '',
        email: '',
        role: '',
        id: ''
      },
      roleOptions: [],
      roleSelected: [],
      customerName: '',
      visibleConfirmation: false,
      deletedIndex: 0,
      deletedRole: null,
      initialRoleCount: 0,
      customerKeyword: '',
      debounce: null,
      isFetching: false,
      isSearching: false,
      selectedCustomerId: '',
      newAddedRoleCount: 0
    }
  },
  computed: {
    ...mapGetters('roles', ['customerList']),
    isEditMode() {
      return this.modeForm === 'Edit'
    },
    customerId() {
      return this.$route.params.customerId
    },
    isSubmitDisabled() {
      return !this.selectedCustomerId || !this.options.role || !this.options.email
    }
  },
  mounted() {
    this.modeForm = this.$route.meta.mode
    if (this.modeForm == 'Edit') {
      this.idForm = this.$route.params.id
    }
    this.initData()
    this.getRoleOptions()
    this.clientName()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    searchCustomer() {
      if (this.customerKeyword !== '') {
        clearTimeout(this.debounce)
        let self = this
        this.debounce = setTimeout(() => {
          if (this.customerKeyword !== '') self.getCustomerList(self.customerKeyword)
        }, 300)
      } else {
        this.isSearching = false
      }
    },
    selectCustomer(item) {
      this.selectedCustomerId = item.id
      this.customerKeyword = item.name
      this.isSearching = false
    },
    getCustomerList(keyword = '') {
      this.$store
        .dispatch('roles/GET_LIST_CUSTOMER', {
          masterId: this.customerUserId,
          params: queryString.stringify({
            q: keyword
          })
        })
        .then(() => {
          this.isSearching = true
          this.isFetching = false
        })
    },
    toggleConfirm(deletedIndex, deletedRole) {
      this.deletedIndex = deletedIndex !== undefined && deletedIndex === 0 ? 0 : deletedIndex
      this.deletedRole = deletedRole
      if (this.roleSelected.length === 1) {
        this.alertFailedToDelete()
      } else {
        this.visibleConfirmation = true
      }
    },
    closeConfirmModal() {
      this.visibleConfirmation = false
    },
    getRoleOptions() {
      this.$store.dispatch('roles/GET_LIST_ROLE').then((response) => {
        this.roleOptions = response.data.data
      })
    },
    initData() {
      this.counterProgress = 0
      this.customer = this.$store.getters['customer/customer']
      if (this.modeForm == 'Edit') {
        this.showLoading()
        this.$store
          .dispatch('roles/GET_ROLES', {
            masterId: this.idForm,
            customerId: this.customerId
          })
          .then((resp) => {
            this.options.email = resp.data.data.email
            this.options.customerName = resp.data.data.customerName
            resp.data.data.customerRoles.map((item) => {
              this.roleSelected.push(item)
            })
            this.initialRoleCount = this.roleSelected.length
            this.counterProgress = 100
            this.showProgress = false
            this.stillLoading = false
            this.hideLoading()
          })
      }
    },
    allValidation() {
      let pass = true
      const error = isNotEmptyErrorForm(this.options)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (this.options.email === '') {
        this.errors['email'] = "Email can't be empty!"
        pass = false
      } else if (!emailPattern.test(this.options.email)) {
        this.errors['email'] = 'Incorrect email format!'
        pass = false
      } else if (this.options.role === '') {
        this.errors['role'] = "Role can't be empty, Please select at least 1 role."
        pass = false
      }
      return pass
    },
    submitData() {
      if (this.isEditMode) {
        if (this.initialRoleCount >= this.roleSelected.length && this.roleSelected.length > 0) {
          this.onBack()
        }
      }
      if (!this.allValidation()) return
      this.showLoading()
      if (this.modeForm == 'Edit' || this.modeForm == 'Tambah') {
        const payload = {
          role: this.options.role,
          email: this.options.email
        }
        this.$store
          .dispatch('roles/ADD_ROLES', {
            payload: payload,
            customerId: this.modeForm == 'Edit' ? this.customerId : this.selectedCustomerId
          })
          .then((resp) => {
            if (resp.data.code == 200) {
              showVueToast('Role berhasil di tambahkan', 'success', 2000)
              this.onBack()
            }
          })
          .catch((err) => {
            if (err.code == 400) {
              showVueToast('User is already exist with the selected role!', 'error', 2000)
              this.hideLoading()
            } else if (err.code == 404) {
              showVueToast('User is not registered to our system!', 'error', 2000)
              this.hideLoading()
            } else if (err.code == 500) {
              showVueToast('Make sure you fill in the data correctly!', 'error', 2000)
              this.hideLoading()
            }
          })
      }
    },
    clientName() {
      this.customerName = localStorage.getItem('client-name')
    },
    onBack() {
      this.$router.push('/roles')
    },
    addRole() {
      this.newAddedRoleCount++
      this.roleSelected.push('')
      this.isClicked = false
    },
    alertFailedToDelete() {
      showVueToast("You can't remove this role. Each user must have at least 1 role!", 'error', 4000)
    },
    deleteUserRole(deletedIndex, customerUserRoleId) {
      this.roleSelected.splice(deletedIndex, 1)
      if (customerUserRoleId) {
        this.showLoading()
        this.$store
          .dispatch('roles/DELETE_CUSTOMER_ROLES', {
            customerUserRoleId: customerUserRoleId,
            customerId: this.customerId
          })
          .then(() => {
            this.closeConfirmModal()
            showVueToast('Role deleted successfully!', 'success', 2000)
            this.hideLoading()
          })
      } else {
        this.newAddedRoleCount--
        this.closeConfirmModal()
      }
    }
  }
}
</script>
