var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-contain"
  }, [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v(_vm._s(_vm.$route.meta.label))]), _vm.roleSelected.length == 14 ? _c('Button', {
    attrs: {
      "buttonText": _vm.modeForm === 'Edit' ? 'Update' : 'Submit'
    },
    on: {
      "action": _vm.onBack
    }
  }) : _c('Button', {
    attrs: {
      "buttonText": _vm.modeForm === 'Edit' ? 'Update' : 'Submit',
      "disabled": _vm.isSubmitDisabled && !_vm.isEditMode
    },
    on: {
      "action": _vm.submitData
    }
  })], 1)]), _c('div', {
    staticClass: "shadow-small rounded-lg bg-white mb-6 p-6"
  }, [this.modeForm == 'Tambah' ? _c('div', {
    staticClass: "mb-6 w-1/2"
  }, [_c('div', {
    staticClass: "relative"
  }, [_vm._m(0), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "borderEnabled": "",
      "withIcon": "",
      "placeholder": "Search Customer..."
    },
    on: {
      "input": function input($event) {
        return _vm.searchCustomer();
      }
    },
    model: {
      value: _vm.customerKeyword,
      callback: function callback($$v) {
        _vm.customerKeyword = $$v;
      },
      expression: "customerKeyword"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search')], 1)], 1), _vm.isSearching ? _c('div', {
    staticClass: "absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-black bg-opacity-80 backdrop-filter backdrop-blur-sm z-10"
  }, [_vm.customerList.length > 0 && !_vm.isFetching ? _vm._l(_vm.customerList, function (customer) {
    return _c('div', {
      key: customer.id,
      staticClass: "py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0",
      on: {
        "click": function click($event) {
          return _vm.selectCustomer(customer);
        }
      }
    }, [_vm._v(" " + _vm._s(customer.name) + " ")]);
  }) : _vm._e(), _vm.customerList.length === 0 && !_vm.isFetching ? [_c('p', {
    staticClass: "py-1 px-3 text-white font-normal text-center"
  }, [_vm._v("Customer not found.")])] : _vm._e()], 2) : _vm._e()])]) : _c('div', {
    staticClass: "mb-6 w-1/2"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Customer",
      "borderEnabled": true,
      "disabled": ""
    },
    model: {
      value: _vm.options.customerName,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "customerName", $$v);
      },
      expression: "options.customerName"
    }
  })], 1), this.modeForm == 'Edit' ? _c('div', {
    staticClass: "mb-6 w-1/2"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Email",
      "borderEnabled": true,
      "placeholder": "Enter email",
      "disabled": ""
    },
    model: {
      value: _vm.options.email,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "email", $$v);
      },
      expression: "options.email"
    }
  })], 1) : _c('div', {
    staticClass: "mb-6 w-1/2"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Email",
      "borderEnabled": true,
      "isLabelRequire": "",
      "placeholder": "Enter email",
      "error": _vm.errors['email']
    },
    model: {
      value: _vm.options.email,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "email", $$v);
      },
      expression: "options.email"
    }
  })], 1), _c('div', {
    staticClass: "w-2/4 rounded-lg border-2 p-5"
  }, [_vm._l(_vm.roleSelected, function (data, idx) {
    return _c('div', {
      key: idx,
      staticClass: "flex mb-6"
    }, [_c('div', {
      staticClass: "w-full"
    }, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Role " + _vm._s(idx + 1))]), data.customerUserRoleId != null ? _c('Dropdown', {
      attrs: {
        "placeholder": "Select Role",
        "options": _vm.roleOptions,
        "default": _vm.roleSelected[idx].roleName,
        "disabled": _vm.isDisabled
      }
    }) : _c('Dropdown', {
      attrs: {
        "placeholder": "Select Role",
        "options": _vm.roleOptions,
        "default": _vm.roleSelected[idx].roleName,
        "error": _vm.errors['role']
      },
      model: {
        value: _vm.options.role,
        callback: function callback($$v) {
          _vm.$set(_vm.options, "role", $$v);
        },
        expression: "options.role"
      }
    })], 1), _c('div', {
      staticClass: "w-12 mx-6 flex justify-center items-end pb-2"
    }, [_c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        },
        "tooltip": {
          show: true,
          position: '140%',
          text: 'Delete'
        }
      },
      on: {
        "action": function action($event) {
          return _vm.toggleConfirm(idx, data);
        }
      }
    })], 1)]);
  }), _vm.initialRoleCount >= _vm.roleSelected.length && _vm.roleSelected.length != 14 ? _c('Button', {
    attrs: {
      "buttonText": "Add Role",
      "type": "tertiary"
    },
    on: {
      "action": _vm.addRole
    }
  }) : _vm._e()], 2)]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleConfirmation,
      "width": "640px",
      "id": "confirm"
    },
    on: {
      "close": _vm.closeConfirmModal
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "py-5"
  }, [_c('div', {
    staticClass: "font-bold text-2xl mb-5"
  }, [_vm._v("Are you sure to remove this role?")]), _c('div', {
    staticClass: "flex justify-center items-center gap-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Remove",
      "type": "secondary",
      "size": "long"
    },
    on: {
      "action": function action($event) {
        var _vm$deletedRole;

        return _vm.deleteUserRole(_vm.deletedIndex, (_vm$deletedRole = _vm.deletedRole) === null || _vm$deletedRole === void 0 ? void 0 : _vm$deletedRole.customerUserRoleId);
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "primary",
      "size": "long"
    },
    on: {
      "action": function action($event) {
        return _vm.closeConfirmModal();
      }
    }
  })], 1)])])], 2)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Select Customer "), _c('span', {
    staticClass: "text-red"
  }, [_vm._v("*")])]);
}]

export { render, staticRenderFns }